/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import { GlobalPendingIndicator } from "~/components/global-pending-indicator";
import { LoaderFunction, LoaderFunctionArgs } from "@remix-run/node";
import { getSession, themeSessionResolver } from "~/server/session.server";
import { getDomainUrl } from "~/lib/utils";
import { getHints, getDeviceCategory } from "~/lib/client-hints";
import {
  PreventFlashOnWrongTheme,
  ThemeProvider,
  useTheme,
} from "remix-themes";
// eslint-disable-next-line import/no-named-as-default
import clsx from "clsx";
import LogRocket from "~/components/controls/logrocket";
import { ClientOnly } from "remix-utils/client-only";

import "@fontsource-variable/dm-sans";
import { AlertCircle, ArrowLeft, Home, RefreshCw } from "lucide-react";
import { SessionProvider } from "./contexts/auth";
import { getEnv } from "./server/env.server";
import { FeatureProvider } from "./components/controls/feature";
import { featureFlags } from "./lib/features";

const fonts = {
  dm: "DM Sans Variable, sans-serif",
  telex: "Telex, sans-serif",
};
const fontFamily = fonts.dm;

export const loader: LoaderFunction = async ({
  request,
}: LoaderFunctionArgs) => {
  const { getTheme } = await themeSessionResolver(request);
  const session = await getSession(request.headers.get("cookie"));

  const user = session.get("user");
  const organisations = user?.profiles?.map(
    (profile: any) => profile.organisation
  );

  return {
    LOGROCKET_APP_ID: "ctrlv-solutions/quickbridge",
    HOST: request.headers.get("host"),
    session: {
      user: user,
      profile: session.get("profile") || user?.profile,
      organisations: organisations,
    },
    theme: getTheme(),
    requestInfo: {
      hints: getHints(request),
      origin: getDomainUrl(request),
      path: new URL(request.url).pathname,
      deviceKind: getDeviceCategory(request),
    },
    ENV: getEnv(),
  };
};

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();

  return (
    <ThemeProvider specifiedTheme={data.theme} themeAction="/action/set-theme">
      <SessionProvider session={data.session}>
        <FeatureProvider featureFlags={featureFlags}>
          <App />
        </FeatureProvider>
      </SessionProvider>
    </ThemeProvider>
  );
}

// Application with Theme and Providers
export default withSentry(AppWithProviders);

// Main Application Component
export function App() {
  const data = useLoaderData<typeof loader>();
  const [theme] = useTheme();

  return (
    <html lang="en" className={clsx(theme)} style={{ fontFamily }}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(data.theme)} />
        <Links />
      </head>
      <body>
        <GlobalPendingIndicator />
        <Outlet />
        <ScrollRestoration />
        <ClientOnly>
          {() => <LogRocket appId={data.LOGROCKET_APP_ID || ""} />}
        </ClientOnly>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  let status = 500;
  let title = "Oops!";
  let message = "Something went wrong on our end.";
  let showActualError = false;

  if (isRouteErrorResponse(error)) {
    status = error.status;
    showActualError = status !== 500;
    switch (error.status) {
      case 404:
        title = "Page Not Found";
        message = "Sorry, we couldn't find the page you're looking for.";
        break;
      case 403:
        title = "Forbidden";
        message = "You don't have permission to access this page.";
        break;
      case 401:
        title = "Unauthorized";
        message = "Please log in to view this content.";
        break;
      case 400:
        title = "Bad Request";
        message = "There was an issue with your request. Please try again.";
        break;
      default:
        title = "Error";
        message = "Oops! Something went wrong.";
        break;
    }
  } else if (error instanceof Error) {
    showActualError = true;
    message = error.message;
  } else {
    console.error(error);
  }

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <title>QuickBridge - Error</title>
        <Meta />
        <Links />
      </head>
      <body className="h-screen">
        <main className="bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 flex items-center justify-center p-4 h-full">
          <div className="max-w-lg w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <button
                onClick={() => window.history.back()}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 flex items-center"
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                Go Back
              </button>
              <div className="flex items-center justify-center w-16 h-16 rounded-full bg-red-100 dark:bg-red-900">
                <AlertCircle className="w-8 h-8 text-red-600 dark:text-red-300" />
              </div>
            </div>
            <h1 className="text-3xl font-bold text-center text-gray-900 dark:text-gray-100">
              {title}
            </h1>
            <p className="text-xl text-center text-gray-600 dark:text-gray-300">
              Error {status}
            </p>
            <div className="mt-6">
              <div className="bg-red-100 dark:bg-red-900 p-4 rounded-md mb-6">
                <h2 className="font-semibold text-red-700 dark:text-red-300">
                  Error Details
                </h2>
                <p className="text-red-700 dark:text-red-300">{message}</p>
              </div>
              {showActualError && error instanceof Error && (
                <details className="mt-4 text-sm text-gray-600 dark:text-gray-400">
                  <summary className="cursor-pointer hover:underline">
                    Technical Details
                  </summary>
                  <pre className="mt-2 whitespace-pre-wrap break-words bg-gray-100 dark:bg-gray-700 p-4 rounded-md overflow-y-auto max-h-[400px]">
                    {error.stack}
                  </pre>
                </details>
              )}
            </div>
            <div className="flex flex-col sm:flex-row gap-4 justify-center mt-6">
              <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md flex items-center">
                <Home className="mr-2 h-4 w-4" />
                <a href="/">Back to Home</a>
              </button>
              <button
                onClick={() => window.location.reload()}
                className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-md flex items-center"
              >
                <RefreshCw className="mr-2 h-4 w-4" />
                Retry
              </button>
            </div>
          </div>
        </main>
        <Scripts />
      </body>
    </html>
  );
}

export function HydrateFallback() {
  return <h1>Loading...</h1>;
}
